import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import 'heightline/jquery.heightLine';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Swiper from 'swiper';
gsap.registerPlugin(ScrollToPlugin, DrawSVGPlugin, ScrollTrigger);

// スムーススクロール
const smoothScroll = () => {
	const scrollTrigger = document.querySelectorAll('.js-smooth');

	for (let i = 0; i < scrollTrigger.length; i++) {
		scrollTrigger[i].addEventListener('click', ev => {
			ev.preventDefault();
			const navHeight = document.querySelector('header').clientHeight;
			const targetSection = scrollTrigger[i].getAttribute('href');

			const rect = document.querySelector(targetSection).getBoundingClientRect().top;
			const offset = window.pageYOffset;
			const gap = 20;
			const target = rect + offset - navHeight - gap;

			gsap.to(window, 1, {
				scrollTo: { y: target },
				ease: 'power4.out',
			});
		});
	};
};

// headerのマージンを自動で付与する
const headerMargin = () => {
	const header = document.querySelector('header');
	const headerHeight = header.clientHeight;
	const body = document.querySelector('body');
	const addMargin = document.querySelectorAll('.js-add-margin');

	body.style.paddingTop = `${headerHeight}px`;

	for (let i = 0; i < addMargin.length; i++) {
		addMargin[i].style.marginTop = `${headerHeight}px`;
	}
}

const heroSwiper = () => {
	const mySwiper = new Swiper('.hero-slider', {
		loop: true,
		effect: 'fade',
		allowTouchMove: false,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		speed: 3000,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
	});
}

// 店舗詳細ページのswiperを使用したスライダー
const shopSlider = () => {
	const triger = document.querySelector('.js-shop-slider');
	if (!triger) return;

	const swiper = new Swiper('.js-shop-slider', {
		loop: true,
		effect: 'fade',
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		speed: 2000,
		pagination: {
			el: ".shop-fv-pagination",
			type: "fraction", /* この行を追加 */
			renderFraction: (currentClass, totalClass) => {
				return `<span class="${currentClass}"></span><span class="progress"></span><span class="${totalClass}"></span>`;
			}
		},
	});

	swiper.autoplay.stop(); //画面に現れるまでswiperを停止
	$(window).on('scroll load', () => {
		const position = $('.js-shop-slider').offset().top - $(window).innerHeight() + 100
		const scrollTop = $(window).scrollTop();
		if (scrollTop > position) {
			swiper.autoplay.start(); //画面に現れたらswiperを開始
		} else {
			swiper.autoplay.stop(); // 画面外にスクロールしたらswiperを停止
		}
	});
}

// 店舗詳細ページブログのswiperを使用したスライダー
const shopBlogSlider = () => {
	const triger = document.querySelector('.js-shop-blog-slider');
	if (!triger) return;

	const swiper = new Swiper('.js-shop-blog-slider', {
		loop: false,
		speed: 800,
		slidesPerView: 5,
		centeredSlides: true,
		pagination: {
			el: ".shop-pagination",
			type: "bullets",
			clickable: "true",
		},
		breakpoints: {
			374: {
				slidesPerView: 1,
			},
			575: {
				slidesPerView: 1.5,
			},
			1199: {
				slidesPerView: 3,
			},
		},
		// activeSlide以外のスライドを小さくする
		on: {
			slideChange: function () {
				const activeSlide = this.slides[this.activeIndex];
				const slides = this.slides;
				for (let i = 0; i < slides.length; i++) {
					if (slides[i] !== activeSlide) {
						slides[i].style.transform = 'scale(0.8)';
					} else {
						slides[i].style.transform = '';
					}
				}
			},
		},
	});

	swiper.autoplay.stop(); //画面に現れるまでswiperを停止
	$(window).on('scroll load', () => {
		const position = $('.js-shop-blog-slider').offset().top - $(window).innerHeight() + 100;
		const scrollTop = $(window).scrollTop();
		if (scrollTop > position) {
			swiper.autoplay.start(); //画面に現れたらswiperを開始
		} else {
			swiper.autoplay.stop(); // 画面外にスクロールしたらswiperを停止
		}
	});
}

// 県別トップページのトピックスのswiperを利用したスライダー
const topTopicsSwiper = () => {
	const triger = document.querySelector('.pref-top-topics-slider');
	if (!triger) return;

	const swiper = new Swiper('.pref-top-topics-slider', {
		loop: true,
		speed: 800,
		slidesPerView: 5,
		centeredSlides: true,
		autoplay: {
			delay: 3000,
		},
		pagination: {
			el: ".topics-pagination",
			type: "bullets",
			clickable: "true",
		},
		breakpoints: {
			374: {
				slidesPerView: 1,
			},
			575: {
				slidesPerView: 1.5,
			},
			1199: {
				slidesPerView: 3,
			},
		},
		// activeSlide以外のスライドを小さくする
		on: {
			slideChange: function () {
				const activeSlide = this.slides[this.activeIndex];
				const slides = this.slides;
				for (let i = 0; i < slides.length; i++) {
					if (slides[i] !== activeSlide) {
						slides[i].style.transform = 'scale(0.8)';
					} else {
						slides[i].style.transform = '';
					}
				}
			},
		},
	})

	swiper.autoplay.stop(); //画面に現れるまでswiperを停止
	$(window).on('scroll load', () => {
		const position = $('.pref-top-topics-slider').offset().top - $(window).innerHeight() + 100
		const scrollTop = $(window).scrollTop();
		if (scrollTop > position) {
			swiper.autoplay.start(); //画面に現れたらswiperを開始
		} else {
			swiper.autoplay.stop(); // 画面外にスクロールしたらswiperを停止
		}
	});
}

// 県別トップページのブログのswiperを利用したスライダー
const topBlogSwiper = () => {
	const triger = document.querySelector('.pref-top-blog-slider');
	if (!triger) return;

	const swiper = new Swiper('.pref-top-blog-slider', {
		loop: true,
		speed: 800,
		slidesPerView: 5,
		centeredSlides: true,
		pagination: {
			el: ".blog-pagination",
			type: "bullets",
			clickable: "true",
		},
		breakpoints: {
			374: {
				slidesPerView: 1,
			},
			575: {
				slidesPerView: 1.35,
			},
			768: {
				slidesPerView: 2,
			},
			992: {
				slidesPerView: 2.5,
			},
			1199: {
				slidesPerView: 3,
			},
			1400: {
				slidesPerView: 4,
			}
		},
		// activeSlide以外のスライドを小さくする
		on: {
			slideChange: function () {
				const activeSlide = this.slides[this.activeIndex];
				const slides = this.slides;
				for (let i = 0; i < slides.length; i++) {
					if (slides[i] !== activeSlide) {
						slides[i].style.transform = 'scale(0.8)';
					} else {
						slides[i].style.transform = '';
					}
				}
			},
		},
	})

	swiper.autoplay.stop(); //画面に現れるまでswiperを停止
	$(window).on('scroll load', () => {
		const position = $('.pref-top-blog-slider').offset().top - $(window).innerHeight() + 100;
		const scrollTop = $(window).scrollTop();
		if (scrollTop > position) {
			swiper.autoplay.start(); //画面に現れたらswiperを開始
		} else {
			swiper.autoplay.stop(); // 画面外にスクロールしたらswiperを停止
		}
	});
}

// メンテナンスページのサービスのswiperを利用したスライダー
const maintenanceServiceSwiper = () => {
	const triger = document.querySelector('.js-after-service-slider');
	if (!triger) return;

	const swiper = new Swiper('.js-after-service-slider', {
		loop: false,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		speed: 1000,
		pagination: {
			el: ".service-pagination",
			type: "bullets",
			clickable: "true",
		},
		navigation: {
			nextEl: '.service-button-next',
			prevEl: '.service-button-prev',
		},
	});

	swiper.autoplay.stop(); //画面に現れるまでswiperを停止
	$(window).on('scroll load', () => {
		const position = $('.js-after-service-slider').offset().top - $(window).innerHeight() + 100;
		const scrollTop = $(window).scrollTop();
		if (scrollTop > position) {
			swiper.autoplay.start(); //画面に現れたらswiperを開始
		} else {
			swiper.autoplay.stop(); // 画面外にスクロールしたらswiperを停止
		}
	});
}

// 店舗詳細ページのカレンダー
const shopCalendar = async () => {
	// URLに`/shop`が含まれていれば実行する
	const url = window.location.href;
	if (!url.match(/shop_/)) return;

	// 法人コードの定義
	const hojin_cd = '5151810';
	// 拠点コードの取得
	const shopId = document.querySelector('main').dataset.shopid;
	// カレンダーAPIのURL
	const apiUrl = `https://honda-minamikyushu.com/api/shop-calendar.php?hojin_cd=${hojin_cd}&kyoten_cd=${shopId}`;
	// カレンダーAPIのリクエスト関数
	const fetchApi = async (url) => {
		const calData = await fetch(url)
			.then(res => res.json())
			.then(data => {
				return data;
			})
			.catch(err => {
				console.log(err);
			})
		return calData;
	};
	// カレンダーAPIのリクエスト
	const calData = await fetchApi(apiUrl);
	// カレンダーの数
	const calLen = calData.length;
	// 休日 曜日
	const holidayWeek = []; // ["2020/12/16", "2020/10/05", ...];
	// 休日 日
	const holidayDate = []; // ["2020/12/16", "2020/10/05", ...];
	// 営業のみ
	const salesOnly = []; // ["2020/12/16", "2020/10/05", ...];
	// カレンダーの数だけループし、休日と営業のみの日にちを抜き出す
	for (let c = 0; c < calLen; c++) {
		// カレンダーの最大日数の31日分ループする
		for (let i = 0; i < 31; i++) {
			/**
			 *  1 = 通常営業
			 *  2 = 営業のみ
			 *  4 = 休日
			 */
			const dayCnt = i + 1; // 1から31まで入ります
			const paddedNum = (`00${dayCnt}`).slice(-2);
			const addDay = `day${paddedNum}`;
			const { yearAndMonth } = calData[c];
			const year = yearAndMonth.slice(0, 4);
			const month = yearAndMonth.slice(4);
			const day = calData[c][addDay];
			const ymd = `${year}/${month}/${paddedNum}`;

			if (calData[c][addDay] === '4') { // 休日の場合、休日用の配列にpush
				holidayDate.push(ymd);
			} else if (calData[c][addDay] === '2') { //営業のみの場合、営業のみ用の配列にpush
				salesOnly.push(ymd);
			}
		}
	}

	////////////////////////////////////////
	// JSカレンダー
	////////////////////////////////////////

	// カレンダーを挿入する要素の取得
	const insertCal = document.querySelector('.app-deliverydate--date');
	if (insertCal) {

		const weeks = ["日", "月", "火", "水", "木", "金", "土"];
		const date = new Date();
		let year = date.getFullYear();
		let month = date.getMonth() + 1;
		// console.log(month);

		const config = {
			show: 1,
		};

		const showCalendar = (year, month) => {
			for (let i = 0; i < config.show; i++) {
				const calendarHtml = createCalendar(year, month, weeks, date, holidayWeek, holidayDate, salesOnly);
				$("#calendarSec").append(calendarHtml);

				month++;
				if (month > 12) {
					year++;
					month = 1;
				}
			}
		}

		// 前後の月 表示ボタン
		const moveCalendar = (e) => {
			document.querySelector("#calendarSec").innerHTML = "";

			if (e.target.id === "prev") {
				month--;

				if (month < 1) {
					year--;
					month = 12;
				}
			}

			if (e.target.id === "next") {
				month++;
				if (month > 12) {
					year++;
					month = 1;
				}
			}
			showCalendar(year, month);
		}

		document.querySelector("#prev").addEventListener("click", moveCalendar);
		document.querySelector("#next").addEventListener("click", moveCalendar);

		showCalendar(year, month);
	}
}

// 営業日カレンダーページ
const salseCalendar = async () => {
	// URLに`/shop`が含まれていれば実行する
	const url = window.location.href;
	if (!url.match(/calendar/)) return;

	// 法人コードの定義
	const hojin_cd = '5151810';
	// カレンダー情報の定義
	const calInfo = [
		{
			shopId: '01',
			api: `https://honda-minamikyushu.com/api/shop-calendar.php?hojin_cd=${hojin_cd}&kyoten_cd=01`,
			target: '01'
		},
		// {
		// 	shopId: '08',
		// 	api: `https://honda-minamikyushu.com/api/shop-calendar.php?hojin_cd=${hojin_cd}&kyoten_cd=08`,
		// 	target: '02'
		// }
	];

	for (let i = 0; i < calInfo.length; i++) {

		// カレンダーAPIのリクエスト関数
		const fetchApi = async (url) => {
			const calData = await fetch(url)
				.then(res => res.json())
				.then(data => {
					return data;
				})
				.catch(err => {
					console.log(err);
				})
			return calData;
		};
		// カレンダーAPIのリクエスト
		const calData = await fetchApi(calInfo[i].api);
		// カレンダーの数
		const calLen = calData.length;
		// 休日 曜日
		const holidayWeek = []; // ["2020/12/16", "2020/10/05", ...];
		// 休日 日
		const holidayDate = []; // ["2020/12/16", "2020/10/05", ...];
		// 営業のみ
		const salesOnly = []; // ["2020/12/16", "2020/10/05", ...];
		// カレンダーの数だけループし、休日と営業のみの日にちを抜き出す
		for (let c = 0; c < calLen; c++) {
			// カレンダーの最大日数の31日分ループする
			for (let i = 0; i < 31; i++) {
				/**
				 *  1 = 通常営業
				 *  2 = 営業のみ
				 *  4 = 休日
				 */
				const dayCnt = (i + 1); // 1から31まで入ります
				const paddedNum = ('00' + dayCnt).slice(-2);
				const addDay = 'day' + paddedNum;
				const { yearAndMonth } = calData[c];
				const year = yearAndMonth.slice(0, 4);
				const month = yearAndMonth.slice(4);
				const day = calData[c][addDay];
				const ymd = year + '/' + month + '/' + paddedNum;

				if (calData[c][addDay] === '4') { // 休日の場合、休日用の配列にpush
					holidayDate.push(ymd);
				} else if (calData[c][addDay] === '2') { //営業のみの場合、営業のみ用の配列にpush
					salesOnly.push(ymd);
				}
			}
		}

		////////////////////////////////////////
		// JSカレンダー
		////////////////////////////////////////

		// カレンダーを挿入する要素の取得
		const insertCal = document.querySelector(`#cal${calInfo[i].target}`);
		if (insertCal) {

			const weeks = ["日", "月", "火", "水", "木", "金", "土"];
			const date = new Date();
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			// console.log(month);

			const config = {
				show: 2,
			};

			const showCalendar = (year, month) => {
				for (let ii = 0; ii < config.show; ii++) {
					const calendarHtml = createCalendar(year, month, weeks, date, holidayWeek, holidayDate, salesOnly);
					$(`#calendarSec${calInfo[i].target}`).append(calendarHtml);

					month++;
					if (month > 12) {
						year++;
						month = 1;
					}
				}
			}

			// 前後の月 表示ボタン
			const moveCalendar = (e) => {
				document.querySelector(`#calendarSec${calInfo[i].target}`).innerHTML = "";

				if (e.target.id === `prev${calInfo[i].target}`) {
					month--;

					if (month < 1) {
						year--;
						month = 12;
					}
				}

				if (e.target.id === `next${calInfo[i].target}`) {
					month++;
					if (month > 12) {
						year++;
						month = 1;
					}
				}
				showCalendar(year, month);
			}

			document.querySelector(`#prev${calInfo[i].target}`).addEventListener("click", moveCalendar);
			document.querySelector(`#next${calInfo[i].target}`).addEventListener("click", moveCalendar);

			showCalendar(year, month);
		}

	}
}

const createCalendar = (year, month, weeks, date, holidayWeek, holidayDate, salesDate) => {
	const startDate = new Date(year, month - 1, 0); //月の最初の日 > Mon Jun 01 2020 00:00:00 GMT+0900
	const endDate = new Date(year, month, 0); //月の最後の日
	const endDayCount = endDate.getDate(); //月の末日 > 6月なら「30」

	const lastMonthendDate = new Date(year, month - 1, 0); //前月の最後の日の情報
	const lastMonthendDayCount = lastMonthendDate.getDate(); //前月の末日

	const startDay = startDate.getDay() + 1; //月の最初の日の「曜日」
	let dayCount = 1; //日にちのカウント
	let calendarHtml = ""; // HTMLを組み立てる変数

	const showMonth = (`0${month}`).slice(-2);
	let showMonthEng;

	const monthEnglish = {
		1: 'January',
		2: 'February',
		3: 'March',
		4: 'April',
		5: 'May',
		6: 'June',
		7: 'July',
		8: 'August',
		9: 'September',
		10: 'October',
		11: 'November',
		12: 'December',
	};

	if (month) {
		showMonthEng = monthEnglish[month];
	}

	calendarHtml +=
		'<div><p class="calendar__itemTitle">' + showMonth + '<span class="d-inline-block ml-4 mt-3 mont font-weight-light text-uppercase">/' + year + '</span>' + "</p>";
	calendarHtml += '<div class="calendar_wrap">';
	calendarHtml += '<div class="calendar_weeksWrap">';

	// 曜日の「行」を作成
	for (let i = 0; i < weeks.length; i++) {
		calendarHtml += '<div class="calendar__item--day">' + weeks[i] + "</div>"; // <td>日</td>
	}

	calendarHtml += '</div class="calendar_weeksWrap">';

	// 今日の年・月の判定（今年であり、今月であるときtrue）
	const todayFlag = date.getFullYear() === year && date.getMonth() + 1 === month;

	for (let w = 0; w < 6; w++) { // 日数の列を作成
		calendarHtml += '<div class="calendar_dateWrap">';

		for (let day = 0; day < 7; day++) { // 行を作成 日〜土曜日
			if (w === 0 && day < startDay) {
				const num = lastMonthendDayCount - startDay + day + 1;
				calendarHtml += `<div class="calendar__item--disabled"><span>${num}<span></div>`;
			} else if (dayCount > endDayCount) {
				const num = dayCount - endDayCount;
				calendarHtml += `<div class="calendar__item--disabled"><span>${num}<span></div>`;
				dayCount++;

			} else { // その月の範囲を出力

				// 今日の日付にクラスを付与
				const addClassToday = todayFlag && dayCount === date.getDate() ? "is-today " : "";

				// 今日が休業日か営業日かの判定
				if (todayFlag && dayCount === date.getDate()) {
					const businessFlug = document.querySelector('#businessFlug');
					if (businessFlug) {
						const hasHoliday = holidayDate.some(date => date === year + '/' + ('00' + month).slice(-2) + '/' + ('00' + dayCount).slice(-2));
						businessFlug.innerHTML = hasHoliday ? "休業日" : "営業日";
					}
				}

				// タグの前半を作成
				calendarHtml += '<button type="button" class="calendar__item--date ' + addClassToday

				// 休業（単位：週）と休業（単位：日）のクラスを追加
				const offW = new Date(year, month - 1, dayCount);
				const isOffWeek = holidayWeek.includes(offW.getDay());
				const isOffDay = holidayDate.includes(year + '/' + ('00' + month).slice(-2) + '/' + ('00' + dayCount).slice(-2));
				const isSalesDay = salesDate.includes(year + '/' + ('00' + month).slice(-2) + '/' + ('00' + dayCount).slice(-2));
				calendarHtml += isOffWeek || isOffDay ? "is-off " : isSalesDay ? "is-sales " : "";


				// タグの後半を作成
				calendarHtml += '"><div><span>' + dayCount + '</span></div></button>';
				dayCount++;
			}
		}

		calendarHtml += '</div class="calendar_dateWrap">';
	}
	calendarHtml += '</div class="calendar_wrap"></div>';
	return calendarHtml;
}

const staffModal = () => {
	// URLに`/staff`が含まれていれば実行する
	if (!window.location.href.includes('/staff')) return;

	const staffModal = document.querySelector('#staffModal');
	const staffModalBg = document.querySelector('#staffModalBg');
	const toggleModal = (isActive) => {
		staffModal.classList.toggle('is-active', isActive);
		staffModalBg.classList.toggle('is-active', isActive);
	};

	document.querySelectorAll('.staff-modal-open').forEach(btn => {
		btn.addEventListener('click', () => toggleModal(true));
	});

	document.querySelectorAll('.staff-modal-close').forEach(btn => {
		btn.addEventListener('click', () => toggleModal(false));
	});
}

// 営業カレンダーの店舗表示モーダルウインドウ
const calendarShopModal = () => {
	document.querySelectorAll('.holiday-modal-open').forEach(button => {
		button.addEventListener('click', () => {
			const modalName = button.dataset.modal;
			const modalContainer = document.querySelector(`#${modalName}`);
			modalContainer.classList.add('is-active');
		});
	});

	document.querySelectorAll('.holiday-modal-close').forEach(button => {
		button.addEventListener('click', () => {
			document.querySelectorAll('.modal-container').forEach(modalContainer => {
				modalContainer.classList.remove('is-active');
			});
		});
	});
}

// ハンバーガーメニュー
const menuScript = () => {
	const menu = document.querySelector('#menu');
	const menuBtn = document.getElementById('menu-btn');

	if (!menu || !menuBtn) return;

	menuBtn.addEventListener('click', () => {
		const isActive = menuBtn.classList.contains('active');
		menuBtn.classList.toggle('active', !isActive);
		menu.classList.toggle('active', !isActive);
	});
}

// 各県トップページのメインビジュアルのカード
const gsapAnimation = () => {
	gsap.fromTo(".consecutive-animate", { x: 20, autoAlpha: 0 }, {
		scrollTrigger: {
			trigger: "consecutiveContents",
			start: '-=50% bottom',
			once: true,
			// markers: true
		},
		x: 0,
		autoAlpha: 1,
		ease: "power4.easeOut",
		stagger: {
			each: .075,
			from: "start", //最後の要素から再生
		}
	});

	// 各セクションのコンテンツをフワッと出現させる
	let sectionContents = document.querySelectorAll('.sectionContent');
	//IE11でforEach使用するための記述
	Array.prototype.forEach.call(sectionContents, function (sectionContent) {
		gsap.fromTo(sectionContent, { x: 0, y: 100, autoAlpha: 0 }, {
			scrollTrigger: {
				trigger: sectionContent,
				start: 'top 85%',
				once: true,
				// markers: true
			},
			duration: 1.75,
			x: 0,
			y: 0,
			autoAlpha: 1,
			ease: 'circ.out',
			overwrite: 'auto',
		});
	});
}

const usedCarSlider = () => {
	//サムネイル
	const sliderThumbnail = new Swiper('.slider-thumbnail', {
		slidesPerView: 20,
		freeMode: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		spaceBetween: 4,
		breakpoints: {
			767: {
				slidesPerView: 4,
			},
		},
	});

	const thumbClass = 'thumblist-item';
	const mytap = window.ontouchstart === null ? "touchstart" : "click";

	//スライダー
	const slider = new Swiper('.uc-slider', {
		// speed: 1500,
		// autoplay: {
		// 	delay: 2000
		// },
		navigation: {
			nextEl: '.uc-button-next',
			prevEl: '.uc-button-prev',
		},
		pagination: {
			el: '.thumblist',
			type: 'custom',
			renderCustom: function (swiper, current, total) {
				const slides = swiper.slides;
				let html = '';
				for (let i = 0; i < total; i++) {
					if (current == i + 1) {
						html = html + `<div class="${thumbClass} uc-thumbnail-item current" data-slideto="${i}">${slides[i].innerHTML}</div>`;
					} else {
						html = html + `<div class="${thumbClass} uc-thumbnail-item" data-slideto="${i}">${slides[i].innerHTML}</div>`;
					}
				}
				return html;
			}
		},
	});

	const clickThumbs = (() => {
		const thumbItems = document.getElementsByClassName(thumbClass);
		for (let i = 0; i < thumbItems.length; i++) {
			thumbItems[i].addEventListener(mytap, ((e) => {
				const index = Number(thumbItems[i].dataset.slideto);
				slider.slideTo(index);
				// autoplayオプションを使わない場合は、以下をコメントアウトをする
				// setTimeout(slider.autoplay.start, 3000);
			}), false);
		}
	});

	clickThumbs();
	slider.on('slideChange', clickThumbs);
}

//footerのマージンを自動で付与する
const fotterHeightScripts = () => {
	const fixedMenu = document.querySelector('#footer-fix-menu');

	if (!(fixedMenu)) return;

	const body = document.querySelector('body');
	const height = fixedMenu.clientHeight;
	const windowWidth = window.innerWidth;

	if (windowWidth >= 992) {
		body.style.paddingBottom = 0;
	} else {
		body.style.paddingBottom = height + 'px';
	}
}

const sideFixedNav = () => {
	const homeClassElement = document.querySelector('.home');
	const scrollArrowLink = document.querySelector('#footer-fix-menu');
	if (!scrollArrowLink) return;

	const toggleVisibility = (isVisible) => {
		scrollArrowLink.classList.toggle('d-lg-block', isVisible);
		scrollArrowLink.classList.toggle('d-lg-none', !isVisible);
	};

	if (homeClassElement) {
		window.addEventListener('scroll', () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			const scrollThreshold = 550;
			toggleVisibility(scrollTop >= scrollThreshold);
		});
	} else {
		toggleVisibility(true);
	}
}

// オレンジディーラーTOP slider
const orangeSlider = () => {
	const triger = document.querySelector('.orange-slider');
	if (!triger) return;

	const swiper = new Swiper('.orange-slider', {
		loop: true,
		effect: 'fade',
		speed: 3000,
		slidesPerView: 1,
		centeredSlides: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		pagination: {
			el: ".shop-fv-pagination",
			type: "fraction", /* この行を追加 */
			renderFraction: function (currentClass, totalClass) {
				return '<span class="' + currentClass + '"></span><span class="progress"></span><span class="' + totalClass + '"></span>';
			}
		},
	});
}

// total-care heightLine
const totalcareHeightLine = () => {
	$(".mente-text").heightLine();
}

window.addEventListener('scroll', () => {
	const scrollTop = window.scrollY || document.documentElement.scrollTop;
	const scrollThreshold = 100;

	const scrollArrowLink = document.querySelector('#scroll-arrow-link');
	const scrollArrowText = document.querySelector('#scroll-arrow-text');

	if (scrollTop >= scrollThreshold) {
		scrollArrowLink.classList.add('is-top');
		scrollArrowText.textContent = 'TOP';
	} else {
		scrollArrowLink.classList.remove('is-top');
		scrollArrowText.textContent = 'SCROLL';
	}
});

const moreMenuScript = () => {
	const moreMenu = document.querySelectorAll('.pref-menu-more-btn');
	for (let i = 0; i < moreMenu.length; i++) {
		const element = moreMenu[i];

		element.addEventListener('click', () => {
			if (element.classList.contains('active')) {
				element.classList.remove('active');
			} else {
				element.classList.add('active');
			}
		})
	}
}

const morePrefMenuScript = () => {
	const moreMenu = document.querySelectorAll('.top-network-more-btn');

	for (let i = 0; i < moreMenu.length; i++) {
		const element = moreMenu[i];

		element.classList.add('active');

		element.addEventListener('click', () => {
			if (element.classList.contains('active')) {
				element.classList.remove('active');
			} else {
				element.classList.add('active');
			}
		})
	}
}

const togleHoliday = () => {
	// button要素のidに`holiday`が前方一致しているものを取得
	const holidayBtn = document.querySelectorAll('button[id*="holiday"]');

	for (let i = 0; i < holidayBtn.length; i++) {
		// 要素がクリックされたら
		holidayBtn[i].addEventListener('click', () => {
			// 現状のボタンをすべてループする
			for (let j = 0; j < holidayBtn.length; j++) {
				// クリックされたボタンと同じボタンじゃなければクラスを削除する
				if (j !== i) {
					const id = holidayBtn[j].id;
					holidayBtn[j].classList.remove('active');
					document.querySelector(`#${id}_container`).classList.remove('active');
				}
			}
			// クリックされたボタンにクラスを付与する
			holidayBtn[i].classList.add('active');
			document.querySelector(`#${holidayBtn[i].id}_container`).classList.add('active');
		});
	}
};

const emergencyModal = () => {
	const modal = document.querySelector('#emergency-modal');
	if (!modal) return;

	const closeBtn = document.querySelector('.e-modal-close');
	closeBtn.addEventListener('click', () => {
		modal.classList.remove('active');
	})

	const bgCloseBtn = document.querySelector('.e-modal-bg');
	bgCloseBtn.addEventListener('click', () => {
		modal.classList.remove('active');
	})
}

// 展示・試乗車ページ下部のボタンの車種表示の設定
const newcarBtnText = () => {
	// 現在のページのURLを取得
	const currentURL = window.location.href;
	// 展示・試乗車ページ以外では処理を止める
	if (!currentURL.match(/democar-list/)) return;
	// URLからクエリ文字列を取得
	const queryString = currentURL.split('?')[1];
	// クエリ文字列から各クエリパラメータをオブジェクトにパース
	const queryParams = {};
	if (queryString) {
		const pairs = queryString.split('&');
		for (const pair of pairs) {
			const [key, value] = pair.split('=');
			queryParams[key] = decodeURIComponent(value);
		}
	}

	// `kyoten_cd`があれば、処理を止める
	if (Object.prototype.hasOwnProperty.call(queryParams, 'kyoten_cd')) return;
	console.log(queryParams);

	const newcarText = document.querySelector('.ATC-title-text')
	const carTypeFullText = newcarText.innerText;
	const carTypeText = carTypeFullText.replace('展示・試乗車', '').trim();
	const elements = document.querySelectorAll('.newcarText');
	for (const element of elements) {
		element.innerHTML = carTypeText;
	}
}

// 展示・試乗車ページ下部のボタンの車種別リンクの設定
const newcarBtnLink = () => {
	// 現在のページのURLを取得
	const currentURL = window.location.href;
	// 展示・試乗車ページ以外では処理を止める
	if (!currentURL.match(/democar-list/)) return;
	// URLからクエリ文字列を取得
	const queryString = currentURL.split('?')[1];
	// クエリ文字列から各クエリパラメータをオブジェクトにパース
	const queryParams = {};
	if (queryString) {
		const pairs = queryString.split('&');
		for (const pair of pairs) {
			const [key, value] = pair.split('=');
			queryParams[key] = decodeURIComponent(value);
		}
	}

	// href変更対象の取得
	const linkElements = document.querySelectorAll('.cartypelink');

	// `kyoten_cd`があれば
	if (Object.prototype.hasOwnProperty.call(queryParams, 'kyoten_cd')) {
		for (const link of linkElements) {
			const getDemocarPath = link.dataset.democarPath;
			link.href = `${location.origin}${getDemocarPath}`;
		}
	} else {
		// car_name パラメータの値を取得
		const carNameValue = queryParams.car_name;

		// 各リンクに car_name パラメータを追加または置き換える
		for (const link of linkElements) {
			// リンクの href を更新する
			if (link.href.includes('car_name=')) {
				link.href = link.href.replace(/car_name=[^&]*/, `car_name=${carNameValue}`);
			} else {
				link.href += `&car_name=${carNameValue}`;
			}
		}
	}
};

// メンテナンス内容の店舗表示モーダルウインドウ
const maintenanceModal = () => {

	const openButton = document.querySelectorAll('.maintenance-modal-open');
	for (let i = 0; i < openButton.length; i++) {
		openButton[i].addEventListener('click', () => {
			const modalName = openButton[i].dataset.modal;
			const modalContainer = document.querySelector(`#${modalName}`);
			modalContainer.classList.add('is-active');
		})
	}

	const closeButton = document.querySelectorAll('.maintenance-modal-close');
	for (let i = 0; i < closeButton.length; i++) {
		closeButton[i].addEventListener('click', () => {
			const modalContainerClose = document.querySelectorAll('.maintenance-modal-container');
			for (let ii = 0; ii < modalContainerClose.length; ii++) {
				modalContainerClose[ii].classList.remove('is-active');
			}
		})
	}
}

// 要素の高さを取得して一番高いところに揃える
const setEqualHeight = () => {
	// ウィンドウの幅が576px未満の場合は処理を終了
	if (window.innerWidth < 576) {
		return;
	}

	const elements = document.querySelectorAll('.js-getHeight');

	// 要素が存在しない場合は処理を終了
	if (elements.length === 0) {
		return;
	}

	let maxHeight = 0;

	// 高さの最大値を計算
	for (let i = 0; i < elements.length; i++) {
		const height = elements[i].clientHeight;
		if (height > maxHeight) {
			maxHeight = height;
		}
	}

	// 高さを設定
	for (let i = 0; i < elements.length; i++) {
		elements[i].style.height = `${maxHeight}px`;
	}
};

// 車検料金表の画像モーダルウインドウ
const maintenancePriceModal = () => {
	const openButton = document.querySelectorAll('.maintenance-price-open-btn')
	for (let i = 0; i < openButton.length; i++) {
		openButton[i].addEventListener('click', () => {
			const modalName = openButton[i].dataset.modal
			const modalContainer = document.querySelector('#' + modalName)
			if (modalContainer) {
				modalContainer.classList.add('is-active')
			}
		})
	}

	const closeButton = document.querySelectorAll('.modal-close')
	for (let i = 0; i < closeButton.length; i++) {
		closeButton[i].addEventListener('click', () => {
			const modalContainerClose = document.querySelectorAll('.modal-container')
			for (let ii = 0; ii < modalContainerClose.length; ii++) {
				modalContainerClose[ii].classList.remove('is-active')
			}
		})
	}
}

const torsterScripts = () => {
	const modal = document.querySelector('#toaster');

	if (!modal) return;

	const hiddenPc = modal.getAttribute('data-hidden-pc') === 'true';
	const hiddenSp = modal.getAttribute('data-hidden-sp') === 'true';

	window.addEventListener('scroll', () => {
		const isSp = window.innerWidth <= 768;

		// 条件に合うときだけクラスを付与
		if (window.scrollY > 200) {
			if ((isSp && hiddenSp) || (!isSp && hiddenPc)) {
				modal.classList.add('scrolled');
			} else {
				modal.classList.remove('scrolled');
			}
		} else {
			modal.classList.remove('scrolled');
		}
	});
};

document.addEventListener('DOMContentLoaded', () => {
	heroSwiper();
	shopBlogSlider();
	topBlogSwiper();
	topTopicsSwiper();
	maintenanceServiceSwiper();
	shopSlider();
	usedCarSlider();
	orangeSlider();
	gsapAnimation();
	menuScript();
	moreMenuScript();
	morePrefMenuScript();
	emergencyModal();
	smoothScroll();
	salseCalendar();
	maintenanceModal();
	newcarBtnText();
	togleHoliday();
	shopCalendar();
	staffModal();
	calendarShopModal();
	newcarBtnLink();
	headerMargin();
	sideFixedNav();
	maintenancePriceModal();
	torsterScripts();
});

window.addEventListener('load', () => {
	fotterHeightScripts();
	totalcareHeightLine();
	setEqualHeight();
});

window.addEventListener('resize', () => {
	headerMargin();
	fotterHeightScripts();
	setEqualHeight();
})
